<template>
  <v-col cols="12">
    <v-sheet elevation="2" class="pa-6">
      <v-row>
        <v-col cols="12" md="6" v-for="spec of specs" :key="spec.key">
          <ImportDataDetailsRow :spec="spec" />
        </v-col>
      </v-row>
    </v-sheet>
  </v-col>
</template>

<script>
import ImportDataDetailsRow from "@views/Restricted/Navision/Imports/Show/partials/ImportDataDetailsRow.vue";

export default {
  name: "ImportDataSpecSheet",
  components: { ImportDataDetailsRow },

  props: {
    specs: {
      type: Array,
      required: true,
    },
  },
};
</script>
