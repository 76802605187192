<template>
  <ModuleSubPage full-width>
    <BaseHasPermission :permissionNames="['unitop.import.read']">
      <v-row class="mb-8">
        <v-col cols="12">
          <BaseBackButton />
        </v-col>
      </v-row>

      <ImportDataDetails :import-data="importData" v-if="!isLoading" />

      <BaseLoadingProgress v-if="isLoading" />
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import BaseHasPermission from "@components/Base/BaseHasPermission.vue";
import ImportDataDetails from "./partials/ImportDataDetails.vue";
import BaseBackButton from "@components/Base/BaseBackButton.vue";
import BaseLoadingProgress from "@components/Base/BaseLoadingProgress.vue";

export default {
  name: "ImportShow",

  data() {
    return {
      isLoading: false,
      importData: {},
    };
  },

  components: {
    BaseLoadingProgress,
    BaseBackButton,
    ImportDataDetails,
    BaseHasPermission,
    ModuleSubPage,
  },

  computed: {
    importId() {
      return this.$route.params.importId || null;
    },
  },

  methods: {
    async retrieveImportData() {
      if (!this.importId) return;

      this.isLoading = true;

      try {
        const { data } = await this.$axios.unitop.showFailedImport(this.importId);
        this.importData = data.data;
      } finally {
        this.isLoading = false;
      }
    },
  },

  async created() {
    await this.retrieveImportData();
  },
};
</script>
