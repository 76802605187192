<template>
  <v-row>
    <ImportDataSpecSheet :specs="labeledImportData" />
    <ImportDataSpecSheet :specs="labeledTypeData" />
  </v-row>
</template>

<script>
import ImportDataSpecSheet from "@views/Restricted/Navision/Imports/Show/partials/ImportDataSpecSheet.vue";

export default {
  name: "ImportShowSeminar",

  components: { ImportDataSpecSheet },

  props: {
    importData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    typeData() {
      if (!this.importData) return [];

      return this.importData.objectData;
    },

    labeledImportData() {
      if (!this.importData) return [];

      const importData = { ...this.importData };
      delete importData["objectData"];

      return Object.entries(importData).map(([key, value]) => ({ key, value }));
    },

    labeledTypeData() {
      if (!this.typeData) return [];

      const typeData = Object.entries(this.typeData);

      return typeData.map(([key, value]) => ({ key, value }));
    },
  },
};
</script>
