<template>
  <div>
    <div>
      <BaseText bold>
        {{ spec.key }}
      </BaseText>
    </div>

    <div v-if="isDate">
      <BaseText>{{ dateTime(spec.value) }}</BaseText>
    </div>

    <div v-if="isObject">
      <ul>
        <li v-for="item in renderObjectToArray" :key="item">
          <BaseText>{{ item }}</BaseText>
        </li>
      </ul>
    </div>

    <BaseText>
      <div v-if="!isDate && !isObject" v-html="spec.value"></div>
    </BaseText>
  </div>
</template>

<script>
import { isObject } from "lodash";
import dateFilter from "@mixins/dateFilter";
import regex from "@/data/regex";
import BaseText from "@components/Base/BaseText.vue";

export default {
  name: "ImportDataDetailsRow",
  components: { BaseText },

  mixins: [dateFilter],

  props: {
    spec: {
      type: Object,
      required: true,
      default: () => ({
        key: null,
        value: null,
      }),
    },
  },

  computed: {
    isObject() {
      return isObject(this.spec.value);
    },

    isDate() {
      return regex.isoDate.test(this.spec.value);
    },

    renderObjectToArray() {
      if (!this.isObject) return;

      return Object.values(this.spec.value).map(([value]) => value);
    },
  },
};
</script>
